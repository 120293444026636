<template>
  <div class="w-shops-singed-wrap">
    <div class="w-crumbs-href">
      <router-link
        tag="span"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        :to="{ path: '/implement' }"
      >招商实施管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <span class="w-white">商铺签约信息</span>
    </div>
    <div
      class="w-shops-main"
      v-if="hasPermission"
    >
      <div class="w-screen-wrap">
        <div class="w-screen-list">
          <div class="w-screen-top">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">项目名称：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="mallCode"
                @change="changeProject"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="status"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">商户：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="merchantCode"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in merchantsList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">店铺：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="storeCode"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="w-screen-bottom">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">品牌：</span>
              <!-- <el-select
                popper-class="w-block-select-down"
                v-model="brandCode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in brandNameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-autocomplete
                v-model="brandName"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入品牌关键字"
                clearable
                @select="handleSelect"
              ></el-autocomplete>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">商铺合同号：</span>
              <input
                class="el-input__inner"
                type="text"
                v-model="contractCode"
                placeholder="请输入商铺合同号"
              />
            </div>
            <!-- <div class="w-input-item w-input-sty">
              <span class="w-type-name">token：</span>
              <input class="el-input__inner" type="text" v-model="token" placeholder="填入token" />
            </div> -->
            <div
              class="w-query-btn"
              @click="searchSinged()"
            >
              <span>查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-singed-table">
        <el-table
          :data="brandList.dataList"
          style="width: 100%"
          @row-dblclick="toContractDetail"
        >
          <el-table-column
            prop="contractCode"
            label="商铺合同号"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="statusText"
            label="状态"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="merchantName"
            label="商户"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="storeName"
            label="店铺"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="brandName"
            label="品牌"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="signDate"
            label="签约日期"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="effectDate"
            label="合同生效日期"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="cutoffDate"
            label="合同失效日期"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="tenancy"
            label="合同租赁天数"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="创建人"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="180"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="toContractDetail(scope.row)"
                type="text"
                size="small"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <table>
          <thead>
            <tr>
              <td width="120">商铺合同号</td>
              <td width="120">状态</td>
              <td width="100">商户</td>
              <td width="120">店铺</td>
              <td width="120">品牌</td>
              <td width="140">签约日期</td>
              <td width="140">合同生效日期</td>
              <td width="140">合同失效日期</td>
              <td width="140">合同租凭天数</td>
              <td width="120">创建人</td>
              <td width="140">创建时间</td>
              <td width="80">查看</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in brandList"
              :key="index"
            >
              <td width="120">{{item.contractCode}}</td>
              <td width="120">{{item.statusText}}</td>
              <td width="120">{{item.merchantName}}</td>
              <td width="120">{{item.storeName}}</td>
              <td width="120">{{item.brandName}}</td>
              <td width="120">{{item.signDate}}</td>
              <td width="120">{{item.effectDate}}</td>
              <td width="140">{{item.cutoffDate}}</td>
              <td width="140">{{item.tenancy}}</td>
              <td width="140">{{item.createUserName}}</td>
              <td width="120">{{item.createTime}}</td>
              <td
                width="80"
                class="w-main-color cursor"
                @click="toContractDetail(item)"
              >查看</td>
            </tr>
          </tbody>
        </table> -->
        <!-- <div
          class="no-recommend-brand"
          style="padding-top: 80px;"
          v-if="brandList.dataList && brandList.dataList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无相关数据</p>
        </div> -->
        <div class="w-page-sty">
          <el-pagination
            v-if="brandList.total > 20"
            background
            @current-change="pageChange"
            layout="prev, pager, next"
            :page-size="brandList.pageSize"
            :total="brandList.total"
            :current-page="pageNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      brandName: '',
      brandCode: '',
      mallCode: '',
      projectList: [],
      statusList: [
        { value: '', label: '全部' },
        { value: 1, label: '未完善' },
        { value: 2, label: '新增待确认' },
        { value: 3, label: '审核未通过' },
        { value: 4, label: '审批中' },
        { value: 5, label: '已确认' },
        { value: 6, label: '已转为正式合同' },
        { value: 7, label: '已终止' },
        { value: 8, label: '终止待确认' },
        { value: 9, label: '终止已确认' },
        { value: 10, label: '变更待确认' },
        { value: 11, label: '已清算' }
      ],
      status: '',
      data: {},
      pageNum: 1,
      brandList: {},
      storeList: [],
      storeCode: '',
      merchantsList: [],
      merchantCode: '',
      userAuthorities: [],
      hasPermission: true, // show
      contractCode: '',
      modulesList: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 75
    })
    if (this.userAuthorities.length) {
      this.getBerthProjectFunc()
      this.getModularListFunc()
      this.hasPermission = Boolean(this.userAuthorities[0].func)
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    toContractDetail (row) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          // /omsviews/rent/contract/intentionContract/details/mainBodyInfo?contractCode=7988-GDWS20201223001&contractId=1341663395865489410&mallCode=7988
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + row.contractCode + '&contractId=' + row.contractId + '&mallCode=' + this.projectId + '&menuCode=12&tokenval=' + getCookie('wisdom_token') + '&operateStep=3', '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    querySearchAsync (queryString, cb) {
      if (queryString) {
        const data = {
          name: queryString
        }
        this.axios.post(api.getIdByName, data)
          .then((res) => {
            cb(res.data.data)
          })
      }
    },
    handleSelect (item) {
      this.brandCode = item.id
    },
    getMerchantsListFunc () {
      const data = {
        mallCode: this.mallCode
      }
      this.axios.post(api.getMerchantList, data)
        .then((res) => {
          this.merchantsList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.merchantName
            obj.value = item.merchantCode
            return obj
          })
          this.merchantsList.unshift({ label: '全部', value: '' })
        })
    },
    getStoreListFunc () {
      const data = {
        mallCode: this.mallCode
      }
      this.axios.post(api.getMallCodeList, data)
        .then((res) => {
          this.storeList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.storeName
            obj.value = item.storeCode
            return obj
          })
          this.storeList.unshift({ label: '全部', value: '' })
        })
    },
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.getProjectIdFunc().then((res) => {
            this.mallCode = res.data.data.projectId
            this.projectName = res.data.data.projectName
            this.getStoreListFunc()
            this.getMerchantsListFunc()
            this.getSingedList()
          })
        })
    },
    changeProject (val) {
      this.selectProjectObj = this.projectList.find((item) => {
        return item.value === val
      })
      this.projectName = this.selectProjectObj.projectName
      this.mallCode = val
      this.updateProjectIdFunc()
      this.pageNum = 1
      this.searchBtn()
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.mallCode
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    getSingedList () {
      if (JSON.parse(localStorage.getItem('saveScreen'))) {
        this.data = JSON.parse(localStorage.getItem('saveScreen'))
        this.contractCode = this.data.contractCode
        this.status = this.data.status
        this.merchantCode = this.data.merchantCode
        this.storeCode = this.data.storeCode
        this.brandCode = this.data.brandCode
        this.data.currentPage = this.pageNum
      } else {
        this.data.mallCode = this.mallCode
      }
      this.data.pageSize = 20
      this.data.currentPage = this.pageNum
      this.axios.post(api.berthSignedMng, this.data)
        .then((res) => {
          this.brandList = res.data.data
          this.brandList.dataList = res.data.data.dataList.map((item) => {
            if (item.status === 1) {
              item.statusText = '未完善'
            } else if (item.status === 2) {
              item.statusText = '新增待确认'
            } else if (item.status === 3) {
              item.statusText = '审核未通过'
            } else if (item.status === 4) {
              item.statusText = '审批中'
            } else if (item.status === 5) {
              item.statusText = '已确认'
            } else if (item.status === 6) {
              item.statusText = '已转为正式合同'
            } else if (item.status === 7) {
              item.statusText = '已终止'
            } else if (item.status === 8) {
              item.statusText = '终止待确认'
            } else if (item.status === 9) {
              item.statusText = '终止已确认'
            } else if (item.status === 10) {
              item.statusText = '变更待确认'
            } else if (item.status === 11) {
              item.statusText = '已清算'
            }
            return item
          })
        })
    },
    searchSinged () {
      if (this.brandName) {
        this.data.brandCode = this.brandCode
      } else {
        this.data.brandCode = ''
      }
      this.pageNum = 1
      this.data.mallCode = this.mallCode
      this.data.contractCode = this.contractCode
      this.data.status = this.status
      this.data.merchantCode = this.merchantCode
      this.data.storeCode = this.storeCode
      localStorage.setItem('saveScreen', JSON.stringify(this.data))
      this.getSingedList()
      this.getStoreListFunc()
      this.getMerchantsListFunc()
    },
    pageChange (val) {
      this.pageNum = val
      this.getSingedList()
    }
  }
}
</script>

<style lang="stylus" scoped>
.el-input__inner
  font-size 14px
.w-shops-singed-wrap
  margin-top 10px
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-screen-wrap
    height 180px
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 11px
    padding-left 42px
    padding-top 29px
    box-sizing border-box
    .w-screen-list
      max-width 1690px
      .w-screen-top
        display flex
        .w-input-item
          display flex
          align-items center
          margin-right 50px
          .el-input__inner
            font-size 14px
          .w-type-name
            display inline-block
            width 90px
            text-align right
            color #fff
            font-size 14px
            font-weight 400
      .w-screen-bottom
        display flex
        margin-top 35px
        .w-input-item
          display flex
          align-items center
          margin-right 50px
          .w-type-name
            display inline-block
            width 90px
            text-align right
            color #fff
            font-size 14px
            font-weight 400
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding 0 24px
    padding-bottom 35px
    table
      width 100%
      thead
        tr
          height 54px
          line-height 54px
          color #999
          font-size 12px
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            // text-indent 14px
            padding-left 10px
      tbody
        tr
          height 47px
          line-height 47px
          color #ffffff
          font-size 12px
          &:hover td
            background-color #23252b
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            // text-indent 14px
            padding-left 10px
</style>
